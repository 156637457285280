import styled from "styled-components"

import { TPartialLocation } from "src/router/routeTypes"
import { minimumGray } from "src/ui/colors"
import { InternalLink } from "src/ui/Link/InternalLink"
import { BodyMixin } from "src/ui/MText"

export interface IBreadcrumb {
  to: TPartialLocation
  label: string
}
export function Breadcrumbs({ items }: { items: IBreadcrumb[] }) {
  return (
    <Nav>
      <OrderedList>
        {items.map((item, index) => {
          return (
            <li key={`${item.to.pathname}-${index}`}>
              <BreadcrumbLink key={item.to.pathname} to={item.to}>
                {item.label}
              </BreadcrumbLink>
            </li>
          )
        })}
      </OrderedList>
    </Nav>
  )
}

const Nav = styled.nav``

const OrderedList = styled.ol`
  margin: 0;
  padding: 0;

  & > li:not(:first-child)::before {
    ${BodyMixin};
    content: "›";
    margin: auto 0.5rem;
    color: ${minimumGray};
  }

  & > li {
    display: inline-block;
  }
`

const BreadcrumbLink = styled(InternalLink)`
  ${BodyMixin};
  color: ${minimumGray};
`
